@import "colors.scss";

.blurred-section {
  margin-top: 35vh;
  position: relative;
  overflow: hidden;
}
/* Stili per il filtro di sfocatura */
.blurred-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 110%;
  height: 110%;
  background-color: $blue-logo;
  // background-image: url("../assets/background.jpeg");
  background-size: cover;
  background-position: center;
  // filter: blur(30px) brightness(140%) saturate(70%);
  z-index: -1;
  clip-path: inset(
    0 0 0 0
  ); /* Non ci sarà alcun effetto di sfocatura intorno ai bordi */
}

/* Stili per il testo all'interno del div */
.blurred-section-content {
  text-align: left;
  padding: 2rem 20%;
  color: white; /* Colore del testo */
}

@media (max-width: 1000px) {
  .blurred-section-content {
    padding: 2rem 10%;
  }
}

@media (max-width: 375px) {
  .blurred-section-content {
    padding: 2rem 5%;
  }
}

.blurred-section-content h2 {
  margin-bottom: 20px;
}
.blurred-section-content p {
  font-size:medium;
  font-weight: 300;
}